import styled from 'styled-components';
import BubbleImage from '../../../assets/bubble.png';

export const RestaurantOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 259px;
  height: 153px;
  background: url(${BubbleImage});
  padding: 11px;
`;

export const RestaurantOverlayInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 20px;
  width: 58px;
  height: 24px;
  background: #1d84d7;
  margin-bottom: 11px;
`;

export const Font14Div = styled.div`
  font-size: 14px;
`;

export const NameDiv = styled.div`
  font-size: 24px;
  margin-bottom: 9px;
`;

export const RegisterIcon = styled.img`
  width: 70px;
  height: 70px;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999;
  cursor: pointer;
`;
