import axios, { AxiosResponse } from 'axios';

export const foodKillerInstance = axios.create({
  baseURL: process.env.REACT_APP_FOOD_KILLER_API_URL,
  timeout: 1_000,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const getFavoriteRestaurant = () => {
  return foodKillerInstance.get<undefined, AxiosResponse<RestaurantDTO[]>>(
    '/v1/favorites?type=RESTAURANT',
  );
};

export const getFavoriteCafe = () => {
  return foodKillerInstance.get<undefined, AxiosResponse<CafeDTO[]>>(
    '/v1/favorites?type=CAFE',
  );
};


enum FavoriteType {
  RESTAURANT = 'RESTAURANT',
  CAFE = 'CAFE',
}


export interface CafeDTO {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  content: string;
}


export interface RestaurantDTO {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  content: string;
}
