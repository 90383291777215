import { CustomOverlayMap, Map, MapMarker } from 'react-kakao-maps-sdk';
import { useQuery } from 'react-query';
import { getFavoriteRestaurant, RestaurantDTO } from '../../config/axios-config';
import { AxiosResponse } from 'axios';
import RestaurantPin from '../../assets/restaurant-pin.png';
import RegisterButton from '../../assets/register-button.png';
import { useState } from 'react';
import {
  Font14Div,
  NameDiv,
  RegisterIcon,
  RestaurantOverlayContainer,
  RestaurantOverlayInnerContainer,
} from './components/styled';
import { useNavigate } from 'react-router-dom';

export const HomeIndex = () => {
  const { data: restaurants } = useQuery('favorites', () => getFavoriteRestaurant(), {
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();

  return (
    <>
      <RegisterIcon src={RegisterButton} onClick={() => navigate('/register')} />
      <Map center={{ lat: 37.547728926666586, lng: 127.05285499377813 }} style={{ width: '100vw', height: '100vh' }}>
        <FloaticMapMarker />
        {restaurants ? <RestaurantMapMarkers restaurants={restaurants} /> : null}
      </Map>
    </>
  );
};

function FloaticMapMarker() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <MapMarker
      position={{ lat: 37.547728926666586, lng: 127.05285499377813 }}
      clickable={true}
      onClick={() => setIsVisible(!isVisible)}
    >
      {isVisible && <div style={{ color: '#000' }}>🍷플로틱</div>}
    </MapMarker>
  );
}

function RestaurantMapMarkers({ restaurants }: RestaurantMapMarkersProps) {
  return (
    <>
      {restaurants.data.map((restaurant) => (
        <RestaurantMapMarker
          key={restaurant.id}
          position={{ lat: parseFloat(restaurant.latitude), lng: parseFloat(restaurant.longitude) }}
          name={restaurant.name}
          content={restaurant.content}
        />
      ))}
    </>
  );
}

function RestaurantMapMarker({ position, name, content }: BasicMapMarkerProps) {
  return <BaseMapMarker pinImage={RestaurantPin} position={position} name={name} content={content} />;
}

function BaseMapMarker({ pinImage, position, name, content }: BaseMapMarkerProps) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <MapMarker
        position={position}
        image={{
          src: pinImage,
          size: { width: 30, height: 30 },
        }}
        clickable={true}
        onClick={() => setIsVisible(!isVisible)}
      ></MapMarker>
      {isVisible && (
        <CustomOverlayMap position={{ lat: position.lat, lng: position.lng }} xAnchor={0.5} yAnchor={1.16}>
          <RestaurantOverlay name={name} content={content} />
        </CustomOverlayMap>
      )}
    </>
  );
}

function RestaurantOverlay({ name, content }: RestaurantOverlayProps) {
  return (
    <RestaurantOverlayContainer>
      <RestaurantOverlayInnerContainer>
        <Font14Div>맛집</Font14Div>
      </RestaurantOverlayInnerContainer>
      <NameDiv>{name}</NameDiv>
      <Font14Div>{content}</Font14Div>
    </RestaurantOverlayContainer>
  );
}

interface RestaurantMapMarkersProps {
  restaurants: AxiosResponse<RestaurantDTO[]>;
}

interface BasicMapMarkerProps {
  position: { lat: number; lng: number };
  name: string;
  content: string;
}

interface BaseMapMarkerProps {
  pinImage: string;
  position: { lat: number; lng: number };
  name: string;
  content: string;
}

interface RestaurantOverlayProps {
  name: string;
  content: string;
}
